import React, { useState, useCallback, useEffect } from "react";
import { Routes, Navigate } from "react-router-dom";
import { Route } from "react-router-dom";

import "./App.css";

import Users from "./users/pages/users";
import MainNavigation from "./shared/components/navigations/mainnavigation";
import Intro from "./information/pages/intro";
import Offerings from "./information/pages/offerings";
import WhoCanJoin from "./information/pages/whocanjoin";
import Connect from "./information/pages/connect";
import Auth from "./users/pages/auth";
import { AuthContext } from "./shared/context/auth-context";
import AboutUs from "./information/pages/aboutUs";
import Footer from "./shared/components/Footer/Footer";
import ScrollToTop from "./shared/components/UIElements/ScrollToTop";
import { ScrollContainer } from "react-scroll-motion";
import Home from "./information/pages/home";

// const Users = React.lazy(() => import('./users/pages/users'))
// const Auth = React.lazy(() => import('./users/pages/auth'))

let logoutTimer;
const App = () => {
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState()

  const login = useCallback((uid, token, expirationDate) => {
    setToken(token);
    setUserId(uid);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(tokenExpirationDate)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if(token && tokenExpirationDate){
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime)
    }
    else{
      clearTimeout(logoutTimer)
    }
  },[token, logout, tokenExpirationDate])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(storedData.userId, storedData.token, new Date(storedData.expiration));
    }
  }, [login])

  let routes;
  if (token) {
    routes = (
      <React.Fragment>
        <Route
          path="/"
          element={
            // <div>
            //   <Intro /> <Offerings /> <WhoCanJoin /> <Connect />
            // </div>
            <div><Home/> <Connect/></div>
          }
        />
        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/query" element={<Users />} />
        <Route path="*" element={<Navigate to="/query" replace />} />
      </React.Fragment>
    );
  } else {
    routes = (
      <React.Fragment>
        <Route
          path="/"
          element={
            // <div>
            //   <Intro /> <Offerings /> <WhoCanJoin /> <Connect />
            // </div>
            <div><Home/> <Connect/></div>
          }
        />
        <Route path="/aboutus" element={<AboutUs/>} />

        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<Navigate to="/auth" replace />} />
      </React.Fragment>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <div>
        <MainNavigation />
        <main>
          <Routes>
            {routes}
          </Routes>
        </main>
        <Footer/>
      </div>
    </AuthContext.Provider>
  );
};
export default App;
