import React from "react";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";


import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/utils/validators";
import Input from "../../shared/components/FormElements/input";
import Button from "../../shared/components/FormElements/button";
import "./connect.css";
import { useForm } from "../../shared/hooks/form-hook";
import Card from "../../shared/components/UIElements/Card";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

const Connect = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      standard: {
        value: "",
        isValid: false,
      },
      userQuery: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const querySubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        "http://127.0.0.1:5001/mycarrercounsellingbackend/us-central1/app/api/query",
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          email: formState.inputs.email.value,
          standard: formState.inputs.standard.value,
          userQuery: formState.inputs.userQuery.value,
        }),
        { "Content-Type": "application/json" }
      );
      window.scrollTo(0,0)
    } catch (err) {}
  };

  return (
    <React.Fragment>
      {/* <Card> */}
        <div className="connect-container">
          <h2 className="connect-heading">Want to join our community?</h2>
          <h3 className="connect-msg">
            Fill out the form given below and someone from our team will contact
            you.
          </h3>
          <div>
            <ErrorModal error={error} onClear={clearError} />
            <form className="query-form" onSubmit={querySubmitHandler}>
              {isLoading && <LoadingSpinner asOverlay />}
              <Input
                id="name"
                element="input"
                type="text"
                label="Name"
                placeholder="Please enter your full name."
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid name"
                onInput={inputHandler}
              />
              <Input
                id="email"
                element="input"
                type="text"
                label="Email"
                placeholder="Please enter your email id."
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email"
                onInput={inputHandler}
              />
              <Input
                id="standard"
                element="input"
                type="text"
                label="Standard"
                placeholder="Please enter the current class you are studying in."
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid standard"
                onInput={inputHandler}
              />
              <Input
                id="userQuery"
                element="text-area"
                type="text"
                rows="2"
                label="Your Query"
                placeholder="Please enter your query."
                validators={[VALIDATOR_MINLENGTH(5)]}
                errorText="Please enter a valid query"
                onInput={inputHandler}
              />
              <Button type="submit" disabled={!formState.isValid}>
                Submit
              </Button>
            </form>
          </div>
        </div>
      {/* </Card> */}
    </React.Fragment>
  );
};

export default Connect;
