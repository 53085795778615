import React from "react";
import "animate.css";

import "./aboutUs.css";
import Card from "../../shared/components/UIElements/Card";
import {
  Animator,
  Fade,
  FadeIn,
  FadeOut,
  MoveIn,
  MoveOut,
  ScrollContainer,
  ScrollPage,
  Sticky,
  StickyIn,
  ZoomIn,
  ZoomOut,
  batch,
} from "react-scroll-motion";

const AboutUs = () => {
  return (
    <ScrollContainer>
      <ScrollPage>
        <div className="content-box-mission">
          <div className="aboutUs-mission-headings">
            <Animator animation={batch(Fade(), MoveOut(-100, 0))}>
              <h1>Our Mission</h1>
            </Animator>
          </div>
          <div className="aboutUs-mission-content">
            <Animator animation={batch(MoveOut(100, 0))}>
              <Card>
                To empower and inspire students throughout their educational
                journey by providing personalized mentorship, guidance, and
                resources that promote academic excellence, personal
                development, and future success.
              </Card>
            </Animator>
          </div>
        </div>
      </ScrollPage>

      <ScrollPage>
        <div className="content-box-vision">
          {/* <div className="aboutUs-vision-headings">
            <Animator animation={batch(FadeIn(), MoveIn(-200,0))}>
              <h1>Our Vision</h1>
            </Animator>
          </div> */}
          <div className="aboutUs-vision-content">
            <Animator animation={batch(Fade(), MoveIn(-100,0), MoveOut(-100,0))}>
              <Card>
                To create a transformative mentoring experience that empowers
                students with the knowledge, skills, and confidence to overcome
                obstacles, set ambitious objectives, and realize their maximum
                potential. We envision a future in which every student in India
                has access to high-quality mentoring that unleashes their
                potential and enables them to create a prosperous future.
              </Card>
            </Animator>
          </div>
          <div className="aboutUs-vision-headings">
            <Animator animation={batch(Fade(), MoveIn(100,0), MoveOut(100,0))}>
              <h1>Our Vision</h1>
            </Animator>
          </div>
        </div>
      </ScrollPage>

      <ScrollPage>
        <div className="content-box-values">
          <div className="aboutUs-values-headings">
            <Animator animation={batch(Fade(),MoveIn(-100,0), MoveOut(-100,0))}>
              <h1>Our Core Values</h1>
            </Animator>
          </div>
          <div className="aboutUs-values-content">
            <Animator animation={batch(FadeIn(), MoveIn(0,-100), MoveOut(0,-100))}>
              <Card>
                <strong>Excellence:</strong> We are committed to maintaining
                high-quality standards in all aspects of our mentorship program.
                We strive for excellence in mentor selection, program delivery,
                and support provided to students, ensuring the best possible
                outcomes.
              </Card>
            </Animator>
          </div>
          <div className="aboutUs-values-content">
            <Animator animation={batch(FadeIn(), MoveIn(100, 0), MoveOut(100,0))}>
              <Card>
                <strong>Integrity:</strong> We uphold the highest levels of
                integrity and ethical conduct. We promote honesty, trust, and
                transparency in all interactions between mentors, mentees, and
                program stakeholders.
              </Card>
            </Animator>
          </div>
        </div>
      </ScrollPage>

      <ScrollPage>
        <div className="content-box-values">
          <div className="aboutUs-values-content">
            <Animator animation={batch(FadeIn(), MoveIn(-100, 0), MoveOut(-100, 0))}>
              <Card>
                <strong>Inclusivity:</strong> We embrace diversity and
                inclusivity in our mentorship program. We respect and value
                students, mentors, and stakeholders' unique backgrounds,
                experiences, and perspectives. We strive to create a safe,
                inclusive, and supportive environment for all participants.
              </Card>
            </Animator>
          </div>
          <div className="aboutUs-values-content">
            <Animator animation={batch(FadeIn(), MoveIn(100,0), MoveOut(100, 0))}>
              <Card>
                <strong>Empathy:</strong> We value empathy and understanding as
                essential components of effective mentorship. We actively
                listen, empathize, and support students, recognizing and valuing
                their unique strengths, challenges, and aspirations.
              </Card>
            </Animator>
          </div>
        </div>
      </ScrollPage>

      <ScrollPage>
        <div className="content-box-values">
          <div className="aboutUs-values-content">
            <Animator animation={batch(FadeIn(), MoveIn(-100,0), MoveOut(-100, 0))}>
              <Card>
                <strong>Collaboration:</strong> We believe in the power of
                collaboration and teamwork. We foster an environment that
                encourages cooperation, open communication, and partnership
                among mentors, mentees, and program stakeholders, recognizing
                that collective efforts lead to greater impact.
              </Card>
            </Animator>
          </div>
          <div className="aboutUs-values-content">
            <Animator animation={batch(FadeIn(), MoveIn(100,0), MoveOut(100, 0))}>
              <Card>
                <strong>Impact:</strong> We are driven by a commitment to make a
                positive and lasting impact on students' lives. We measure our
                success by the transformation we bring about in their academic
                performance, personal development, and long-term success.
              </Card>
            </Animator>
          </div>
        </div>
      </ScrollPage>
    </ScrollContainer>
  );
};

export default AboutUs;
