import React, { useContext, useEffect, useState } from "react";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import UsersList from "../components/usersList";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const Users = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError} = useHttpClient();
  const [loadedUserQuery, setLoadedUserQuery] = useState();
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          // `${process.env.REACT_APP_BACKEND_URL}/query`,
          "http://127.0.0.1:5001/mycarrercounsellingbackend/us-central1/app/api/query",
          "Get",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        setLoadedUserQuery(responseData.queries);
      } catch (err) {
        console.log(err)
      }
    };
    fetchUsers();
  }, [sendRequest,auth.token]);

  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      {isLoading && !loadedUserQuery &&(
        <div className="centre">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedUserQuery && <UsersList items={loadedUserQuery} />}
    </React.Fragment>
  );
};

export default Users;
