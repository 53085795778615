import React from "react";

import "./home.css";
import {
  Animator,
  Fade,
  FadeIn,
  MoveIn,
  MoveOut,
  ScrollContainer,
  ScrollPage,
  Sticky,
  StickyIn,
  ZoomIn,
  ZoomOut,
  batch,
} from "react-scroll-motion";
import Card from "../../shared/components/UIElements/Card";
import img from "../../img1.png";

const Home = () => {
  return (
    <ScrollContainer>
      <ScrollPage>
        <div className="content-box-intro">
          <Animator
            animation={batch(StickyIn(), FadeIn(), MoveOut(200, 0))}
            delay="500"
          >
            <Card className="intro-content">
              Welcome to My Career Counseling! Whether you're a high school
              student or a concerned parent, our platform is here to provide you
              with comprehensive career guidance. Explore a wide range of
              industries and discover exciting career options that align with
              your interests and aspirations. Our team of dedicated mentors is
              ready to support you on your journey toward a fulfilling and
              successful future. Let's embark on this transformative career
              exploration together.
            </Card>
          </Animator>
        </div>
      </ScrollPage>
      <ScrollPage>
        <div className="content-box-offerings">
          <div className="offerings-content">
            <Animator animation={batch(FadeIn(), MoveOut(-200, 0))}>
              <h2>Learn from the people who were in your shoes</h2>
              <Card>
                We have mentors who were there in your shoes a few years back.
                So they understand what you are feeling and can understand your
                situation in a better way. You can learn from their mistakes and
                try to avoid them in your decisions.
              </Card>
            </Animator>
          </div>
          <div className="offerings-content">
            <Animator animation={batch(FadeIn(), MoveOut(0, -200))}>
              <h2>No hidden charges/motives</h2>
              <Card>
                We are group of motivated individuals who wants to make a change
                in the society. We are trying to build a community to bring a
                change in the society and the programs that we conduct is
                completely free of charge.
              </Card>
            </Animator>
          </div>
          <div className="offerings-heading">
            <Animator animation={batch(FadeIn(), MoveOut(200, 0))}>
              <h1>What is there for you?</h1>
            </Animator>
          </div>
        </div>
      </ScrollPage>
      <ScrollPage>
        <div className="content-box-journey">
        <div className="journey-heading">
            <Animator animation={batch(FadeIn(),MoveIn(-100,0), MoveOut(-100, 0))}>
              <h1>Your Journey</h1>
            </Animator>
          </div>
          <div className="img-content">
            <Animator animation={batch(FadeIn(), MoveIn(100,0), MoveOut(100,0))}>
              <img className="img1" alt="img1" src={img}></img>
            </Animator>
          </div>
        </div>
      </ScrollPage>
      <ScrollPage>
        <div className="content-box-who-can-join">
          <div className="who-can-join-content">
            <ul>
              <li className="who-can-join-list">
                <Animator animation={batch(Fade(), MoveOut(-100, 0))}>
                    <Card>
                    Currently we have launched the program for students of class 10th to
                    12th.
                    </Card>
                </Animator>
              </li>
              <li className="who-can-join-list">
                <Animator animation={batch(Fade(), MoveOut(0, 0))}>
                    <Card>
                    Students who needs guidance in their career exploration can join our
            community.
                    </Card>
                </Animator>
              </li>
              <li className="who-can-join-list">
                <Animator animation={batch(Fade(), MoveOut(100, 0))}>
                    <Card>
                    We are planning to launch program for other students as well in the
            near future.
                    </Card>
                </Animator>
              </li>
            </ul>
          </div>
          <div className="who-can-join-heading">
            <Animator animation={batch(FadeIn(), MoveOut(100, 0))}>
              <h1>Who can join?</h1>
            </Animator>
          </div>
        </div>
      </ScrollPage>
    </ScrollContainer>
  );
};

export default Home;
