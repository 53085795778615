import React, {useContext} from "react";
import {NavLink} from 'react-router-dom'

import './navLinks.css';
import { AuthContext } from "../../context/auth-context";

const NavLinks = props =>{

    const auth = useContext(AuthContext)
    return <ul className="nav-links">
        <li>
            <NavLink to="/" exact ="true">Home</NavLink>
        </li>
        {auth.isLoggedIn &&(
        <li>
            <NavLink to="/query">Queries</NavLink>
        </li>
        )}
        {/* <li>
            <NavLink to="/testimonials">Testimonmials</NavLink>
        </li> */}
        <li>
            <NavLink to="/aboutus">About Us</NavLink>
        </li>
        {!auth.isLoggedIn &&(
        <li>
            <NavLink to="/auth">Login</NavLink>
        </li>
        )}
        {auth.isLoggedIn &&(
            <li>
                <button onClick={auth.logout}>Logout</button>
            </li>
        )}
    </ul>
}

export default NavLinks;