import React from 'react';
// import {
//   MDBFooter,
//   MDBContainer,
//   MDBIcon,
//   MDBInput,
//   MDBCol,
//   MDBRow,
//   MDBBtn
// } from 'mdb-react-ui-kit';
import './Footer.css'

export default function Footer() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
        <div className='links-container'>
          <div className="aboutus-footer">
            <h6>About</h6>
            <p className="text-justify">About Us text</p>
          </div>
        
          <div className="footer-links-content">
            <h6>Categories</h6>
            <ul className="footer-links">
              <li><a href="/aboutus">About Us</a></li>
              <li><a href="#">Team</a></li>
              <li><a href="#">FAQ</a></li>
              <li><a>Contact Us</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>

          <div className="footer-links-content">
            <h6>Info</h6>
            <ul className="footer-links">
              <li>Phone : 0000000000</li>
              <li>E-mail : test@test.com</li>
            </ul>
          </div>
          </div>
        </div>
        <hr/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2017 All Rights Reserved by <a href="/">mentorconnect.co.in</a>.
            </p>
          </div>

          {/* <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
              <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
              <li><a className="dribbble" href="#"><i className="fa fa-dribbble"></i></a></li>
              <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>   
            </ul>
          </div> */}
        </div>
      </div>
</footer>
  );
}