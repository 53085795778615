import React from 'react';

import UserItem from './UserItem';
import Card from '../../shared/components/UIElements/Card';
import './UsersList.css';

const UsersList = props => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No users found.</h2>
        </Card>
      </div>
    );
  }

  return (
    <ul className="users-list">
      {props.items.map(query => (
        <UserItem
          key={query.id}
          id={query.id}
          name={query.name}
          email={query.email}
          standard={query.standard}
          userQuery={query.userQuery}
        />
      ))}
    </ul>
  );
};

export default UsersList;
